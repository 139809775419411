import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import TitleContent from '@components/TitleContent'
import RichFeaturedLinks from '@components/RichFeaturedLinks'
import ImageContent from '@components/ImageContent'
import Links from '@components/Articles/Links'
import Team from '@components/Training/Team'
import Contact from '@components/Training/Contact'
import { colors } from '@styles/vars/colors.style'

const TrainingPage = ({ data, location }) => {
  const {
    slug,
    seo,
    intro,
    featuredTrainingLink,
    richTrainingLinks,
    featuredImagecontent,
    trainingUpdates,
    trainingResources,
    team,
    contactInformation,
  } = data.contentfulTraining

  return (
    <>
      <Seo data={seo} slug={slug} />
      <TitleContent
        title={intro.title}
        content={intro.content}
        alignment={intro.alignmentDesktop}
      />
      <RichFeaturedLinks blocks={[featuredTrainingLink]} compact={true} />
      <RichFeaturedLinks blocks={richTrainingLinks} inset={false} />
      <ImageContent
        image={featuredImagecontent.imageLandscape}
        imageLabel={featuredImagecontent.imageLabel}
        content={featuredImagecontent.content}
        flip={featuredImagecontent.flip}
        headingSize={'small'}
        wide={true}
        backgroundColor={colors.lightblue}
      />
      <TitleContent
        title={trainingUpdates.title}
        content={trainingUpdates.content}
        alignment={trainingUpdates.alignmentDesktop}
        pageContent={true}
      />
      {trainingResources && (
        <Links
          title="Royal sales training resources"
          subtitle="All of the sales training resources you might need."
          links={trainingResources}
        />
      )}
      {team && <Team members={team} title="Meet the team" />}
      <Contact content={contactInformation} />
      <Footer location={location} />
    </>
  )
}

export default TrainingPage

export const pageQuery = graphql`
  query TrainingPage {
    contentfulTraining {
      slug
      seo {
        ...SEO
      }
      intro {
        ...TitleContentBlock
      }
      featuredTrainingLink {
        ...ImageContentBlock
      }
      richTrainingLinks {
        ...ImageContentBlock
      }
      featuredImagecontent {
        ...ImageContentBlock
      }
      trainingUpdates {
        ...TitleContentBlock
      }
      trainingResources {
        ...Link
      }
      team {
        text
        resource {
          gatsbyImageData(width: 568, height: 568)
          description
        }
        jobTitle
      }
      contactInformation {
        raw
      }
    }
  }
`
