import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '@components/Spacer'
import { Heading1 } from '@components/TextStyles'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { Heading3, TextBodySmall } from '@styles/vars/textStyles.style'
import { TeamListWrapper, TeamImage, TeamContent } from './index.style'

const Team = ({ members, title }) => {
  return (
    <TeamListWrapper>
      <Container>
        <AnimateSplitText>
          <Heading1>{title}</Heading1>
        </AnimateSplitText>
        <Spacer size={[20, 48]} />

        <Grid>
          {React.Children.toArray(
            members.map((member, memberIndex) => {
              return (
                <GridItem tabletL={2} tabletLStart={memberIndex % 5 ? 0 : 2}>
                  {member.resource && (
                    <TeamImage>
                      <AnimateFadeIn>
                        <GatsbyImage
                          image={member.resource.gatsbyImageData}
                          alt={member.resource.description}
                        />
                      </AnimateFadeIn>
                    </TeamImage>
                  )}
                  <TeamContent>
                    <AnimateSplitText>
                      <Heading3>{member.text}</Heading3>
                    </AnimateSplitText>
                    <Spacer size={4} />
                    <AnimateSplitText>
                      <TextBodySmall>{member.jobTitle}</TextBodySmall>
                    </AnimateSplitText>
                    <Spacer size={32} />
                  </TeamContent>
                </GridItem>
              )
            })
          )}
        </Grid>
      </Container>
    </TeamListWrapper>
  )
}

Team.propTypes = {
  members: PropTypes.array,
}

export default Team
