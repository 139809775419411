import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'

export const ContactWrapper = styled.div`
  ${clamp('padding-bottom', 64, 160)};
  ${clamp('padding-top', 0, 100)};
  position: relative;
  text-align: center;
  
  a {
    color: ${colors.blue}
  }
`