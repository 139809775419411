import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'

export const TeamListWrapper = styled.div`
  ${clamp('padding-bottom', 28, 48)};
  position: relative;
  text-align: center;
  display: none;

  ${mq.tabletL} {
    display: block;
  }
`

export const TeamImage = styled.div`
  ${clamp('border-radius', 1000, 1000)};
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: block;
  margin: 0 auto;
  text-align: center;
  max-width: 30rem;
`

export const TeamContent = styled.div`
  text-align: center;
  margin-top: 1rem;
`