import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RichText from '@components/RichText'
import Spacer from '@components/Spacer'

import {
  ContactWrapper
} from './index.style'

const Contact = ({ content }) => {
  return (
    <ContactWrapper>
      <Container>
        <Grid>
          <GridItem desk={12}>
            <RichText content={content} />
            <Spacer size={24} />
          </GridItem>
        </Grid>
      </Container>
    </ContactWrapper>
  )
}

Contact.propTypes = {
  content: PropTypes.object
}

export default Contact